import React from "react";
import { data } from "./utils";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import PageContentHeader from "../../component/pageHeaderContent";

import "./styles.scss";
import { MdWork } from "react-icons/md";

import { FaCodeBranch } from "react-icons/fa";
const Resume = () => {
  return (
    <section id="resume" className="resume">
      <PageContentHeader
        headerText="Deneyimlerim"
        icon={<FaCodeBranch size={40} />}
      />
      <div className="timeline">
        <div className="timeline_experience">
          <h3 className="timeline_experience_header-text">Deneyimlerim</h3>
          <VerticalTimeline
            layout={"1-column"}
            lineColor="var(--yellow-theme-main-color)"
          >
            {data.experience.map((item, i) => (
              <VerticalTimelineElement
                key={i}
                className="timeline_experience_verticaltimeline_element"
                contentStyle={{
                  background: "none",
                  color: "var(--yellow-theme-sub-text-color)",
                  border: "1.5px solid var(--yellow-theme-main-color)",
                }}
                icon={<MdWork />}
                iconStyle={{
                  background: "#181818",
                  color: "var(--yellow-theme-main-color)",
                }}
              >
                <div className="verticaltmeline-element-title-wrapper">
                  <h3>{item.title}</h3>
                  <h4>{item.subTitle}</h4>
                </div>
                <p className="verticaltmeline-element-title-wrapper_description"> {item.description}</p>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
        <div className="timeline_education">
          <h3 className="timeline_education_header-text">Eğitimlerim</h3>
          <VerticalTimeline
            layout={"1-column"}
            lineColor="var(--yellow-theme-main-color)"
          >
            {data.education.map((item, i) => (
              <VerticalTimelineElement
                key={i}
                className="timeline_education_verticaltimeline_element"
                contentStyle={{
                  background: "none",
                  color: "var(--yellow-theme-sub-text-color)",
                  border: "1.5px solid var(--yellow-theme-main-color)",
                }}
                icon={<MdWork />}
                iconStyle={{
                  background: "#181818",
                  color: "var(--yellow-theme-main-color)",
                }}
              >
                <div className="verticaltmeline-element-title-wrapper">
                  <h3>{item.title}</h3>
                
                </div>
                <p className="verticaltmeline-element-title-wrapper_description">
                  {" "}
                  {item.description}
                </p>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
      </div>
    </section>
  );
};

export default Resume;
