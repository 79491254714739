export const skillsData = [
  {
    label: "FRONT END",
    data: [
      {
        skillNama: "HTML",
        percentage: "80",
      },
      {
        skillNama: "CSS",
        percentage: "80",
      },
      {
        skillNama: "JAVASCRİPT",
        percentage: "80",
      },
      {
        skillNama: "Scss",
        percentage: "85",
      },
      {
        skillNama: "React",
        percentage: "90",
      },
      {
        skillNama: "Graphql",
        percentage: "65",
      },
    ],
  },
  {
    label: "DATABASE",
    data: [
      {
        skillNama: "MONGO DB",
        percentage: "90",
      },
      {
        skillNama: "MsSQL",
        percentage: "50",
      },
      {
        skillNama: "FİREBASE",
        percentage: "80",
      },
    ],
  },
  {
    label: "BACK END",
    data: [
      {
        skillNama: "NodeJs",
        percentage: "90",
      },
      {
        skillNama: "Swagger",
        percentage: "60",
      },
      {
        skillNama: "c",
        percentage: "50",
      },
      {
        skillNama: "C++",
        percentage: "50",
      },
      {
        skillNama: "Docker",
        percentage: "50",
      },
    ],
  },
  {
    label: "Sistem Bilgisi",
    data: [
      {
        skillNama: "Github",
        percentage: "85",
      },
      {
        skillNama: "Siber Güvenlik",
        percentage: "55",
      },
      {
        skillNama: "Web Pentesting",
        percentage: "60",
      },
      {
        skillNama: "Kali Linux",
        percentage: "70",
      },
      {
        skillNama: "Burb Suit",
        percentage: "65",
      },
    ],
  },
];
