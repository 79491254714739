import React, { useState, useEffect } from "react";
import "./styles.scss";
import {
  FaInstagram,
  FaLinkedinIn,
  FaGithub,
  FaEnvelope,
} from "react-icons/fa";
import { Animate } from "react-simple-animate";
const Home = () => {
    const phrases = [
      "Software Engineer",
      "Backend Developer",
      "Frontend Developer",
      "Web Developer",
    ];
  
    const [displayText, setDisplayText] = useState('');
    const [phraseIndex, setPhraseIndex] = useState(0);
    const speed = 100; 
  
    useEffect(() => {
      let i = 0;
      const typingInterval = setInterval(() => {
        if (i < phrases[phraseIndex].length) {
          setDisplayText(prevText => prevText + phrases[phraseIndex].charAt(i));
          i++;
        } else {
          clearInterval(typingInterval);
          setTimeout(() => {
            setDisplayText(''); 
            setPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
          }, 1000); 
        }
      }, speed);
  
      return () => {
        clearInterval(typingInterval);
      };
    /* eslint-disable react-hooks/exhaustive-deps */}, [phraseIndex, speed]); 
  
    
  


  return (
    <section id="home" className="home">
      <div className="home_text-wrapper">
        <h1>Merhaba , Ben Enes</h1>
        <h1>{displayText}|</h1>
      </div>
      <Animate
        play
        duration={1.5}
        delay={1}
        start={{ transform: "translateY(550px)" }}
        end={{ transform: "translatex(0px)" }}
      >
        <div className="home_contact-me">
          <div className="home_contact-me_icon">
            <a
              href="https://github.com/Eness2001"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>
            <a
              href="https://www.instagram.com/monsterofwebsite/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="mailto:eneskutulman012@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaEnvelope />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/enes-kutulman-b1208a200/"
              target="_blank"
            >
              <FaLinkedinIn />
            </a>
          </div>
        </div>
      </Animate>
    </section>
  );
};

export default Home;
