import React from "react";
import PageContentHeader from "../../component/pageHeaderContent";
import { BsInfoCircleFill } from "react-icons/bs";
import { Animate } from "react-simple-animate";
import "./styles.scss";
import { DiMongodb, DiNodejs } from "react-icons/di";
import { FaDatabase, FaDev, FaReact, FaCodeBranch } from "react-icons/fa";

const jobSummarty =
  "12 Ağustos 2001'de Bingöl'ün Merkez ilçesinde doğdum.Çok küçük yaşta İstanbula ailemle beraber geldim.İlköğretim dönemimi tamamlamamın ardından Çağrıbey Anadolu Lisesini kazanarak lise hayatıma başladım.Lise dönemlerinde teknolojiye özellikle yazılıma ilgim vardı ve kendimi bu alanda geliştirmek istiyordum.Lise dönemi ardından İstanbul Gelişim Üniversitesi Bilgisayar Mühendisliği bölümüne tam burslu olarak girmeye hak kazandım ve 2023 ağustos ayı itibariyle üniversite eğitimimi tamamladım.Özel olarak Web uygulamalarıyla ve Web Güvenliği ile ilgileniyorum.";
const About = () => {
  return (
    <section id="about" className="about">
      <PageContentHeader
        headerText="Hakkımda"
        icon={<BsInfoCircleFill size={40} />}
      />
      <div className="about_content">
        <div className="about_content_personalWrapper">
          <Animate
            play
            duration={1}
            delay={1}
            start={{ transform: "translateX(-1000px)" }}
            end={{ transform: "translatex(0px)" }}
          >
            <h3>Yazılım Mühendisliği ve Ben</h3>
            <p>
              {jobSummarty}
              <br />
              <strong>
                İlgili konular üzerine freelancer olarak hizmet veriyorum
              </strong>
            </p>
          </Animate>
          <Animate
            play
            duration={1}
            delay={1}
            start={{ transform: "translateX(2000px)" }}
            end={{ transform: "translatex(0px)" }}
          >
            <button>
              <a className="about_content_personalWrapper_link" href="https://drive.google.com/file/d/1UnxavR26aNYJ4UNPaFUjIAjPox5ngW7B/view" target="_blank" rel="noreferrer">
                CV'Mİ GÖRÜNTÜLE
              </a>
            </button>
          </Animate>
        </div>
        <div className="about_content_servicesWrapper">
          <Animate
            play
            duration={1}
            delay={1}
            start={{
              transform: "translateX(900px)",
            }}
            end={{ transform: "translateX(0px)" }}
          >
            <div className="about_content_servicesWrapper_innerContent">
              <div>
                <FaDev size={60} color="var(--yellow-theme-main-color)" />
              </div>
              <div>
                <FaReact size={60} color="var(--yellow-theme-main-color)" />
              </div>
              <div>
                <FaDatabase size={60} color="var(--yellow-theme-main-color)" />
              </div>
              <div>
                <FaCodeBranch
                  size={60}
                  color="var(--yellow-theme-main-color)"
                />
              </div>
              <div>
                <DiNodejs size={60} color="var(--yellow-theme-main-color)" />
              </div>
              <div>
                <DiMongodb size={60} color="var(--yellow-theme-main-color)" />
              </div>
            </div>
          </Animate>
        </div>
      </div>
    </section>
  );
};

export default About;
