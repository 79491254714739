import React, { useState } from "react";
import PageContentHeader from "../../component/pageHeaderContent";
import Clinifi from "../../images/Clinifi.png";
import KolayKira from "../../images/Kolay Kira.png";
import PersonalSite from "../../images/logo.png";
import { FiCode } from "react-icons/fi";
import "./styles.scss";
const portfolioData = [
  { id: 2, name: "Clinifi App", image: Clinifi, link: "" },
  { id: 2, name: "Kolay Kira", image: KolayKira, link: "" },
  { id: 3, name: "Freelance", image: PersonalSite, link: "" },
];

const filterData = [
  { filterId: 1, label: "Tümü" },
  { filterId: 2, label: "Web uygulamaları" },
  { filterId: 3, label: "Arayüz tasarımı" },
];
const Portfolio = () => {
  const [filteredValue, setFilteredValue] = useState(1);
  const [hoveredValue, setHoveredValue] = useState(null);
  const handleFilter = (currentId) => {
    setFilteredValue(currentId);
  };

  const handleHover = (index) => {
    setHoveredValue(index);
  };

  const filtredItem =
    filteredValue === 1
      ? portfolioData
      : portfolioData.filter((item) => item.id === filteredValue);
  console.log(filtredItem);

  const handleClickButton = () => {
    window.location.href = "/";
  };
  return (
    <section id="portfolio" className="portfolio">
      <PageContentHeader headerText="Portfolyo" icon={<FiCode size={40} />} />
      <div className="portfolio_content">
        <ul className="portfolio_content_filter">
          {filterData.map((item) => (
            <li
              className={item.filterId === filteredValue ? "active" : ""}
              onClick={() => {
                handleFilter(item.filterId);
              }}
              key={item.filterId}
            >
              {item.label}
            </li>
          ))}
        </ul>
        <div className="portfolio_content_cards">
          {filtredItem.map((item, index) => (
            <div
              key={`filteredId${item.name.trim()}`}
              className="portfolio_content_cards_item"
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={() => handleHover(null)}
            >
              <div className="portfolio_content_cards_item_image_wrapper">
                <button>
                  <img src={item.image} alt="dummy data" />
                </button>
              </div>
              <div className="overlay">
                {index === hoveredValue && (
                  <div>
                    <p>{item.name}</p>
                    <button
                      onClick={() => {
                        handleClickButton();
                      }}
                    >
                      Visit
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
