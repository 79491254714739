export const data = {
  experience: [
    {
      title: "Qpien ,",
      subTitle: "Stajyer",
      description:
        "Müşteri İletişim Yönetimi (CRM) uygulaması üreten bir yazılım firması olan Qpiende backend ve frontend geliştirdim. ",
    },
    {
      title: "CliniFi , ",
      subTitle: "Freelancer",
      description:
        "Hasta takip yönetim uygulaması olan Clinifi da Doctor ve Stock modüllerinin arayüz ve backend entegrasyonlarını yaptım",
    },
    {
      title: "Kolay Kira",
      subTitle: "Freelancer",
      description:
        "Emlak uygulaması olan Kolay Kira da Tüm arayüz tasarımlarını ve backend entegrasyonlarını yaptım",
    },
  ],

  education: [
    {
      title: "Sıfırdan Her Yönüyle JavaScript & Node.JS",
      description: "Mehmet Seven",
    },
    {
      title: "Sıfırdan Her Yönüyle React ve Redux ",
      description: "Mehmet Seven",
    },
    {
      title: "Sıfırdan Her Yönüyle GraphQL ve Apollo",
      description: "Mehmet Seven",
    },
    {
      title: "Versiyon Kontrolleri: Git ve GitHub",
      description: "Atıl Samancıoğlu",
    },
    {
      title: "Etik Hacker Olmak: Web Sızma Testleri ve Bug Bounty",
      description: "Atıl Samancıoğlu",
    },
  ],
};
