import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { HiX } from "react-icons/hi";
import logoForWebSite from "../../images/logo.png"
import "./styles.scss";
const data = [
  { label: "ANASAYFA", to: "/" },
  { label: "HAKKIMDA", to: "/about" },
  { label: "YETENEKLERİM", to: "/skılls" },
  { label: "DENEYİMLERİM", to: "/resume" },
  { label: "PORTFOLYO", to: "/portfolio" },
  { label: "İLETİŞİM", to: "/contact" },
];
const Navbar = () => {
  const [toggleIcon, setToggleIcon] = useState(false);
  const handleToggleIcon = () => {
    setToggleIcon(!toggleIcon);
  };
  return (
    <div>
  
        <nav className="navbar">
          <div className="navbar_container">
            <a href={"/"} className="navbar_container_logo">
              <img src={logoForWebSite} alt="logo" className="navbar_container_logo_wrapper"/>
            </a>
          </div>
          <ul className={`navbar_container_menu ${toggleIcon ? "active" : ""}`}>
            {data.map((item, key) => (
              <li key={key} className="navbar_container_menu_item">
                <a href={item.to} className="navbar_container_menu_item_links">
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
          <div className="nav_icon" onClick={handleToggleIcon}>
            {toggleIcon ? <HiX size={30} /> : <FaBars size={30} />}
          </div>
        </nav>
    
    </div>
  );
};

export default Navbar;
