import React from "react";
import PageContentHeader from "../../component/pageHeaderContent";
import { Animate } from "react-simple-animate";
import { MdContactMail } from "react-icons/md";
import { FaPhone, FaLocationArrow } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import "./styles.scss";
const Contact = () => {
  const handleButtonClick = () => {
    window.location.href = "/";
  };
  return (
    <section id="contact" className="contact">
      <PageContentHeader
        headerText="Bana Ulaşın"
        icon={<MdContactMail size={40} />}
      />
      <div className="contact_content">
        <Animate
          play
          duration={1}
          delay={0}
          start={{ transform: "translateX(-200px)" }}
          end={{ transform: "translateX(0px)" }}
        >
          <h3 className="contact_content_header_text">E-Mail Gönder</h3>
        </Animate>
        <Animate
          play
          duration={1}
          delay={0}
          start={{ transform: "translateX(200px)" }}
          end={{ transform: "translateX(0px)" }}
        >
          <div className="contact_content_form">
            <div className="contact_content_form_controlswrapper">
              <div>
                <input required type="text" name="name" className="inputName" />
                <label htmlFor="name" className="nameLabel">
                  Name
                </label>
              </div>
              <div>
                <input
                  required
                  type="text"
                  name="email"
                  className="inputEmail"
                />
                <label htmlFor="email" className="emailLabel">
                  Email
                </label>
              </div>
              <div>
                <textarea
                  required
                  type="text"
                  name="description"
                  className="inputDescription"
                  rows="5"
                />
                <label htmlFor="description" className="descriptionLabel">
                  Description
                </label>
              </div>
            </div>
            <button onClick={handleButtonClick}>Gönder</button>
          </div>
        </Animate>
        <Animate
          play
          duration={1}
          delay={0}
          start={{ transform: "translateX(-200px)" }}
          end={{ transform: "translateX(0px)" }}
        >
          <div className="contact_content_text">
            <h3 className="contact_content_text_header">Bana Ulaşın</h3>
            <p className="contact_content_text_custom_text">
              {" "}
              Projen için ücret teklifi almak, aklına takılan bir soruyu sormak
              ya da "Merhaba!" demek için <br /> mail adreslerimden bana ulaş,
              sosyal medya adreslerimden bana yaz veya sağa altta gördüğün{" "}
              <b>canlı destek </b> <br />
              ikonuna tıkla! Sana en yakın sürede döneceğim{" "}
            </p>
            <div className="contact_content_text_wrapper">
              <div>
                <span>
                  <FaPhone size={30} />
                </span>
                <br />
                <a href="tel://+905412570082">+90 541 257 0082</a>
              </div>
              <div>
                <span>
                  <FaLocationArrow size={30} />
                </span>
                <br />
                <p className="custom_p">Ümraniye / İSTANBUL</p>
              </div>
              <div>
                <span>
                  <MdMail size={30} />
                </span>
                <br />
                <a href="mailto:eneskutulman4@gmail.com">
                  eneskutulman4@gmail.com
                </a>
              </div>
            </div>
          </div>
        </Animate>
        <Animate
          play
          duration={1}
          delay={0}
          start={{ transform: "translateX(200px)" }}
          end={{ transform: "translateX(0px)" }}
        ></Animate>
      </div>
    </section>
  );
};

export default Contact;
