import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/index";
import About from "./pages/About/index";
import Resume from "./pages/Resume/index";
import Skills from "./pages/Skills/index";
import Portfolio from "./pages/Portfolio/index";
import Contact from "./pages/Contact/index";
import Navbar from "./component/navBar/index";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import particles from "./utils/particles";
import "./App.scss";

function App() {
  const handleInit = async (main) => {
    await loadFull(main);
  };
  const location = window.location.pathname;
  const renderParticalJsInHomePage = location === "/";
  return (
    <div className="App">
      {renderParticalJsInHomePage && (
        <Particles options={particles} init={handleInit} />
      )}
      <Navbar />
      <div className="App_main-page-content">
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/skılls" element={<Skills />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
